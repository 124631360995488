<template>
  <v-row dense>
    <v-col cols="4" md="3" lg="2">
      <user-status-select v-model="statusSelection" />
    </v-col>
    <v-col cols="4" md="3" lg="2">
      <creditor-name-select v-model="creditorNameSelection" />
    </v-col>
    <v-col cols="4" md="3" lg="2">
      <user-name-select v-model="userNameSelection" include-company-users />
    </v-col>
    <v-col cols="4" md="3" lg="2">
      <sap-number-select v-model="sapNumberSelection" />
    </v-col>
    <v-col cols="4" md="3" lg="2">
      <city-select v-model="citySelection" />
    </v-col>
    <v-col cols="4" md="3" lg="2">
      <role-select v-model="roleSelection" />
    </v-col>
    <v-col v-if="!isProductAdmin" cols="4" md="3" lg="2">
      <product-select v-model="productSelection" />
    </v-col>
    <v-col v-if="!isProductAdmin" cols="4" md="3" lg="2">
      <official-voucher-number-filter v-model="officialVoucherNumberSelection" />
    </v-col>

    <v-col cols="12" class="pt-3 pb-4">
      <active-filter-chip
        v-for="(selection, index) in statusSelection"
        :key="selection"
        v-model="statusSelection"
        :index="index"
      >
        {{ selection | formatUserStatus }}
      </active-filter-chip>
      <active-filter-chip
        v-for="(selection, index) in creditorNameSelection"
        :key="selection"
        v-model="creditorNameSelection"
        :index="index"
      >
        {{ selection }}
      </active-filter-chip>
      <active-filter-chip
        v-for="(selection, index) in userNameSelection"
        :key="selection"
        v-model="userNameSelection"
        :index="index"
      >
        {{ selection }}
      </active-filter-chip>
      <active-filter-chip
        v-for="(selection, index) in sapNumberSelection"
        :key="selection"
        v-model="sapNumberSelection"
        :index="index"
      >
        {{ selection }}
      </active-filter-chip>
      <active-filter-chip
        v-for="(selection, index) in citySelection"
        :key="selection"
        v-model="citySelection"
        :index="index"
      >
        {{ selection }}
      </active-filter-chip>
      <active-filter-chip
        v-for="(selection, index) in roleSelection"
        :key="selection"
        v-model="roleSelection"
        :index="index"
      >
        {{ $t(`users.roles.${selection}`) }}
      </active-filter-chip>
      <active-filter-chip
        v-for="(selection, index) in productSelection"
        :key="selection"
        v-model="productSelection"
        :index="index"
      >
        {{ selection | formatProduct }}
      </active-filter-chip>
      <active-filter-chip
        v-for="(selection, index) in officialVoucherNumberSelection"
        :key="selection"
        v-model="officialVoucherNumberSelection"
        :index="index"
      >
        {{ $t('users.referenceNumber') }}: {{ selection }}
      </active-filter-chip>

      <app-text-btn
        v-if="hasActiveFilters"
        color="background-text"
        class="caption"
        title="resetFilter"
        @click="resetAllFilter"
      />
    </v-col>
  </v-row>
</template>

<script>
import ActiveFilterChip from '@/shared/components/ActiveFilterChip';
import CitySelect from '@/modules/Admin/components/Filter/CitySelect';
import CreditorListMixin from '@/modules/Admin/mixins/CreditorListMixin';
import CreditorNameSelect from '@/modules/Admin/components/Filter/CreditorNameSelect';
import ListFilterMixin from '@/mixins/ListFilterMixin';
import OfficialVoucherNumberFilter from '@/modules/Admin/components/Filter/OfficialVoucherNumberFilter';
import ProductSelect from '@/modules/Admin/components/Filter/ProductSelect';
import RoleSelect from '@/modules/Admin/components/Filter/RoleSelect';
import SapNumberSelect from '@/modules/Admin/components/Filter/SapNumberSelect';
import UserStatusSelect from '@/modules/Admin/components/Filter/UserStatusSelect';
import UserNameSelect from '@/modules/Admin/components/Filter/UserNameSelect';

export default {
  name: 'UsersFilter',

  mixins: [ListFilterMixin, CreditorListMixin],

  components: {
    ActiveFilterChip,
    CitySelect,
    CreditorNameSelect,
    OfficialVoucherNumberFilter,
    ProductSelect,
    RoleSelect,
    SapNumberSelect,
    UserNameSelect,
    UserStatusSelect
  }
};
</script>
